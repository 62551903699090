@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

$divider-border: 1px solid $lighter-gray;

.topSection {
  padding: 50px 10px 30px 10px;
  text-align: center;

  .title {
    margin-bottom: 12px;
  }
}
.row {
  flex-direction: row;
  display: flex;
  align-items: center;
  border-bottom: $divider-border;
  padding: 10px;

  &:first-child {
    border-top: $divider-border;
  }

  .label {
    padding-right: 4px;
    white-space: nowrap;
  }
}
