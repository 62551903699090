@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

.layout {
  display: grid;
  grid-template-rows: 60px calc(100% - 60px);
  height: 100%;

  @include tablet-large {
    grid-template-rows: 80px calc(100% - 80px);
  }
}
