@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

.formSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  @include tablet-medium {
    width: 50%;
  }

  .buttons {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
  }
}
