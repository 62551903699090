@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.item {
  padding: 15px;
  background: $lightest-gray;
  min-height: 80px;

  @include tablet-small {
    padding: 15px 20px;
  }

  @include tablet-medium {
    padding: 20px 30px;
  }
}

.content {
  display: flex;
  gap: 10px;
  flex-direction: column;

  @include tablet-small {
    flex-direction: row;
    gap: 30px;
  }

  .icon {
    display: flex;
    align-items: center;

    gap: 10px;
    min-width: 55px;

    @include tablet-small {
      flex-direction: column;
      justify-content: center;
    }

    .default {
      color: $navy-light;
    }

    .cardIcon {
      font-size: 30px;
      color: $dark-gray;
    }
  }

  .detail {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;

    @include tablet-small {
      align-items: flex-start;
    }

    .info {
      display: flex;
      align-items: center;

      .label {
        color: $dark-gray;
      }
    }

    .links {
      display: flex;
      align-items: flex-start;
      gap: 10px;

      .link {
        padding: 0;
        transform: none;
        color: $medium-gray;
      }
    }
  }
}
