@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.typography/typography.module.scss';

$border: 1px solid $light-gray;

.searchWrapper {
  margin: 10px 0;
  padding: 10px 10px;
  align-self: center;
  width: 30%;

  .search {
    border-color: $medium-gray;
    font-weight: map-get($text-weights, book);
    color: inherit;
  }
}

.searchWrapperWithSuffix {
  margin: 10px 0;
  align-self: center;
  height: 40px;
  width: 30%;
  border: 1px solid;
  border-color: $medium-gray;
  border-radius: 20px;
  position: relative;
  display: flex;
  align-items: center;

  .search {
    border: 0;
    font-weight: map-get($text-weights, book);
    color: inherit;
  }
}

[data-suffix] {
  &:after {
    content: attr(data-suffix);
    opacity: 0.6;
    font-family: Gotham;
    font-size: 14px;
    margin-right: 2px;
    margin-left: 2px;
  }
}
