@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.videoWrapper {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 10px;

  .video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .videoOverlay {
    z-index: 30;
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: $white;
  }
}

.videoWrapper::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.25),
    rgba(0, 0, 0, 0.25)
  );
  border-radius: 10px;
}
