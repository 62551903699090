@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

.formContainer {
  background: $lightest-gray;
  margin-top: 1px;
}

.formSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  @include tablet-medium {
    width: 50%;
  }
}

.buttons {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
}

.submitButton {
  &[data-emphasis='primary'] {
    background-color: $navy-medium;
    &:disabled {
      background-color: $light-gray;
    }
    &:hover:enabled {
      background-color: $navy-medium;
    }
  }
}
