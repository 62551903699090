.headerContainer {
  margin-bottom: 1em;
  display: flex;

  .backIconWrapper {
    display: flex;
    flex-wrap: wrap;
    align-content: center;

    .backIcon {
      cursor: pointer;
      margin-right: 0.5em;
    }
  }
}
