@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

.daysSelectionContainer {
  flex-direction: column;
  display: flex;
  gap: 1.2em;

  @include tablet-medium {
    flex-direction: row;
  }
}
