@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.typography/typography.module.scss';
@import '~@campgladiator/cgui-core.styles.mixins/mixins.module.scss';

@mixin set-box-shadow($color) {
  box-shadow: 0 0 0 3px rgba($color, 0.2);
}

.wrapper {
  position: relative;
  background-color: $white;
  border: 1px solid $text-gray;
  @include transition-default(border-color);
  overflow: hidden;
  height: fit-content;
  box-sizing: border-box;
  padding: 8px 10px 2px 20px;
  border-radius: 20px;

  .attachmentField {
    box-sizing: border-box;
    background-color: $white;
    border: none;
    width: 100%;
    outline: none;
    resize: none;
    overflow: auto;
    font-weight: 300;
    letter-spacing: -0.2px;
    padding-right: 5px;
    color: $dark-gray;
    height: fit-content;
    min-height: 22px;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    flex-wrap: wrap;
    @-moz-document url-prefix() {
      scrollbar-color: $text-gray $lighter-gray;
    }

    &::placeholder {
      color: $medium-gray;
      font-weight: map-get($text-weights, book);
    }

    &:disabled {
      background-color: $lightest-gray;
      color: $light-gray;

      &::placeholder {
        color: $light-gray;
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: $lighter-gray;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $text-gray;
      border-radius: 100px;
      background-clip: content-box;
    }
  }

  &[data-invalid='true'] {
    background-color: $gladiator-lightest;
    border-color: $gladiator-light;

    &:focus-within {
      border-color: $gladiator-light;
      @include set-box-shadow($gladiator-lighter);
    }

    .attachmentField {
      background-color: $gladiator-lightest;
    }
  }

  &::placeholder {
    color: $medium-gray;
    font-weight: map-get($text-weights, book);
  }

  &:focus-within {
    border-color: $navy-light;
    @include set-box-shadow($navy-light);
  }

  &.disabled {
    background-color: $lightest-gray;
    border: 1px solid $light-gray;
    color: $light-gray;
  }
}
