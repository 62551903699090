@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

.container {
  padding: 30px;

  .title {
    margin-bottom: 30px;
  }

  .sectionContainer {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 50px;

    .formSection {
      margin-top: 20px;
    }

    .buttons {
      display: flex;
      flex-direction: row-reverse;
    }

    .submitButton {
      &[data-emphasis='primary'] {
        background-color: $navy-medium;
        &:disabled {
          background-color: $light-gray;
        }
        &:hover:enabled {
          background-color: $navy-medium;
        }
      }
    }
  }
}
