@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.typography/typography.module.scss';
@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

$border: 1px solid $light-gray;

.page {
  background-color: $lightest-gray;
  border: $border;
  display: flex;
  flex-direction: column;

  .searchSection {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    gap: 20px;
    width: 50%;
    max-width: 500px;
  }
}

.tableWrapper {
  margin-top: 20px;
  padding: 0 50px;

  @include tablet-small {
    padding: 0 30px;
  }
}

.pageHeader {
  flex: 1;
  align-items: center;
  margin-left: 15px;
}

.noData {
  text-align: center;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.modal {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;

  .modalDescription {
    margin-top: 10px;
    color: $navy-medium;
  }

  .modalImage {
    max-width: 300px;
    max-height: 200px;
    object-fit: contain;
    margin: 20px 0;
  }
}
