@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.popover {
  z-index: 10000 !important;
}
.autocompleteContainer {
  position: relative;
  z-index: 20;
}

.menu {
  position: absolute;
  transform: translate(-100px, -100%);
  z-index: 10;
  background-color: $white;
  margin-top: 10px;
  display: flex;

  div {
    max-height: 220px;
  }
}

.loader {
  display: flex;
  justify-content: center;
}
