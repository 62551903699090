.container {
  width: 220px;

  .topSection {
    padding: 50px 10px 30px 10px;
    text-align: center;

    .title {
      margin-bottom: 12px;
    }
  }
}
