@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

.formContainer {
  background: $lightest-gray;
  margin-top: 1px;
}

.profileContainer {
  background: #f9f9f9;
  border: 1px solid #d4d4d4;
  margin-top: 30px;
  margin-bottom: 30px;
  flex-direction: row;
  display: flex;
  padding: 10px;
  align-items: center;

  @include tablet-medium {
    width: 50%;
  }

  .avatar {
    position: relative;
  }

  .editImage {
    position: absolute;
    z-index: 1;
    margin-left: 50px;
    margin-top: 55px;
    width: 30px;
    height: 30px;
    background-color: $white;
    border: 1px solid $lighter-gray;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    display: flex;

    .cameraIcon {
      color: $medium-gray;
    }

    input[type='file'] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .name {
    color: $black;
  }
}

.imageContainer {
  margin-right: 30px;
}

.title {
  color: $dark-gray;
  margin-bottom: 20px;
}

.formSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  @include tablet-medium {
    width: 50%;
  }

  .buttons {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 30px;
  }

  .fieldset {
    gap: 20px;
    margin-top: 0;

    .fieldsetItem {
      flex-basis: 0;
      flex-grow: 1;
    }
  }
}
