@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.container {
  margin: 30px 0;

  .title {
    color: $dark-gray;
    margin-bottom: 10px;
  }

  .row {
    &:last-child {
      border-bottom: 1px solid $light-gray;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
    border-top: 1px solid $light-gray;
    box-sizing: border-box;

    .prodIcon {
      height: 16px;
      width: 16px;
      font-size: 16px;
      color: $medium-gray;
    }
    .label1 {
      color: $medium-gray;
      padding-left: 6px;
    }
    .label2 {
      color: $gladiator-light;
      margin: 0 4px;
    }
    .label3 {
      color: $gladiator-light;
    }
  }
}
