@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.item {
  padding: 15px;
  background: $lightest-gray;
  min-height: 80px;
  flex-grow: 1;
  display: flex;
  align-items: stretch;
}

.content {
  display: flex;
  gap: 10px;
  flex-direction: row;

  .icon {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    min-width: 55px;

    .default {
      color: $navy-light;
    }

    .cardIcon {
      font-size: 30px;
      color: $dark-gray;
    }
  }

  .detail {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .info {
      display: flex;
      align-items: center;

      .label {
        color: $dark-gray;
      }
    }

    .links {
      display: flex;
      align-items: flex-start;
      gap: 10px;

      .link {
        padding: 0;
        transform: none;
        color: $medium-gray;
      }
    }
  }
}
