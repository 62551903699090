@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

$border: 1px solid $light-gray;

.page {
  background-color: $lightest-gray;
  border: $border;
  display: flex;
  flex-direction: column;
}

.tableWrapper {
  padding: 0 50px;
}

.pageHeader {
  flex: 1;
  align-items: center;
  margin-left: 15px;
}

.noData {
  text-align: center;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
