@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.container {
  padding: 0 20px;
  max-width: calc(100vw - 360px);
}

.chargebackFlag {
  border: 1px solid $dark-gray;
}
