@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.wrapper {
  padding: 15px 15px 15px 0;
  position: relative;
  display: inline-flex;

  .button[data-testid='close'] {
    background: $white;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 30;
  }
}
