.tableContainer {
  max-width: calc(100vw - 360px);
  padding: 0 20px;
  .trainerColumn {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  .pagination {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
}
