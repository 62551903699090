@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

.formContainer {
  background: $lightest-gray;
  margin-top: 1px;
}

.title {
  color: $dark-gray;
  margin-bottom: 20px;
}

.formSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;

  .locationTitle {
    color: $dark-gray;
    margin-bottom: 5px;
    margin-top: 20px;
  }

  .buttons {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 30px;
  }

  .submitButton {
    &[data-emphasis='primary'] {
      background-color: $navy-medium;
      &:disabled {
        background-color: $light-gray;
      }
      &:hover:enabled {
        background-color: $navy-medium;
      }
    }
  }

  .formSection {
    width: 100%;
  }

  .fieldset {
    gap: 20px;
    margin-top: 0;

    .fieldsetItem {
      flex-basis: 0;
      flex-grow: 1;
    }

    .fieldsetItemHalf {
      flex-basis: 0;
      flex-grow: 0.5;
    }
  }

  .select {
    width: 100%;
  }

  .textArea {
    min-height: 60px;
  }

  .header {
    color: $dark-gray;
  }
}
