@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.fieldset {
  gap: 20px;
  margin-top: 0 !important;

  .fieldsetItem {
    flex-basis: 0;
    flex-grow: 1;
  }
}

.addRemoveButtons {
  width: 50px;
  gap: 8px;
  display: flex;
  align-items: center;

  img,
  span {
    cursor: pointer;
    gap: 3px;
    color: $gladiator;
  }
}

.errorMessage {
  color: $gladiator-light;
  font-weight: 300;
  letter-spacing: -0.2px;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  margin-right: 80px;
}
