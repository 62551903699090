@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.container {
  padding: 0 20px;
  max-width: calc(100vw - 360px);

  .header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;

    .title {
      color: $dark-gray;
    }

    @include tablet-medium {
      flex-direction: row;
      align-items: center;
      gap: 70px;
    }

    .carrot {
      padding-right: 10px;
    }
    :hover {
      cursor: pointer;
    }
  }

  .transactionHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .newTransactionTypeButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    margin: 20px 0;
  }
}
