.title {
  margin-bottom: 1em;
  display: flex;
}

.backIcon {
  cursor: pointer;
  margin-right: 0.5em;
}

.backIconWrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.tabContainer {
  padding: 20px 30px 20px 55px;
}
