@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

.formSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  @include tablet-medium {
    width: 50%;
  }

  .buttons {
    display: flex;
    flex-direction: row-reverse;

    .editButtons {
      display: flex;
      gap: 40px;
    }
  }

  .fieldset {
    gap: 20px;
    margin-top: 0;

    .fieldsetItem {
      flex-basis: 0;
      flex-grow: 1;
    }
  }

  .textArea {
    min-height: 60px;
  }

  .select {
    width: 100%;
  }

  .fileUploader {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
