@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.typography/typography.module.scss';

.tab {
  width: 100%;
}

.tabContainer {
  margin-block-start: 0em;
  padding-inline-start: 0px;
  border-bottom: 2px solid $light-gray;
}

.tabContainer {
  &.inline {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }

  .tabItem {
    display: inline-block;
    margin-left: 0;
    padding: 10px;
    cursor: pointer;
    padding-left: 30px;
    padding-right: 30px;

    &.selected {
      border-bottom: 3px solid $dark-gray;

      & .tabTitle {
        color: $dark-gray;
      }
    }

    & .tabTitle {
      color: $medium-gray;
      text-transform: capitalize;
    }
  }
}
