@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.selected {
  background-color: $lighter-gray;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .card {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    &:hover {
      background-color: $lighter-gray;
      cursor: pointer;
    }

    .trainer {
      display: flex;
      flex-direction: row;
      gap: 10px;
      .trainerData {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .location {
          display: flex;
          flex-direction: row;

          .icon {
            font-size: 12pt;
          }
        }
      }
    }

    .workout {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 50%;
      color: $navy-light;
    }
  }
}
