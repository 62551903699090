@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

.membershipContainer {
  text-align: left;
  padding: 20px 10px;

  .headerContainer {
    margin-bottom: 1em;
    display: flex;

    .backIconWrapper {
      display: flex;
      flex-wrap: wrap;
      align-content: center;

      .backIcon {
        cursor: pointer;
        margin-right: 0.5em;
      }
    }
  }

  .title {
    color: $dark-gray;
    margin-bottom: 0px;
  }

  .formSection {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin-left: 40px;

    @include tablet-medium {
      width: 50%;
    }

    .fieldsetItem {
      flex-basis: 0;
      flex-grow: 1;
    }

    .paymentLinkContainer {
      display: flex;
      flex-direction: row;

      .link {
        padding: 0;
        margin-left: 5px;
        transform: none;
        color: $medium-gray;
      }
    }

    .radioContainer {
      margin-top: 0px;
      margin-bottom: 5px;
    }

    .buttons {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 30px;
    }
  }
}
