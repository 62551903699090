@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.typography/typography.module.scss';

$border: 1px solid $light-gray;

.title {
  color: $dark-gray;
  background-color: $lightest-gray;
  margin-top: 60px;
  display: flex;
  align-self: normal;
}

.pageHeader {
  flex: 1;
  align-items: center;
  margin-left: 15px;
}

.searchAndButtonContainer {
  justify-content: center;
  display: flex;
  align-items: center;
}

.submitButton {
  margin-right: 30px;
}

.searchWrapper {
  display: flex;
  justify-content: center;
}
