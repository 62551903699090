@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.tabContainer {
  padding: 3em;
}

.filterContainer {
  margin-bottom: 2em;

  .title {
    margin-bottom: 1em;
    color: $dark-gray;
  }

  .filterLabel {
    color: $dark-gray;
    margin-bottom: 0.5em;
  }

  .filterDropdown {
    max-width: 350px;
  }
}
