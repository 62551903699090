@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

.radioCard {
  align-items: baseline;
  border: 1px solid $light-gray;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  margin-top: 10px;

  &:hover {
    background: $lightest-gray;
    border: 1px solid $text-gray;
  }

  &.active {
    border: 1px solid $dark-gray;
  }

  .labelContent {
    align-items: center;
    display: flex;

    .input {
      appearance: none;
      border: 1px solid $text-gray;
      border-radius: 100px;
      box-shadow: none;
      cursor: pointer;
      height: 20px;
      padding: 0;

      &[data-displace='true'] {
        @include phone-only {
          margin-top: 20px;
        }
      }

      &:before {
        border-radius: 100px;
        content: '';
        display: block;
        height: 14px;
        margin: 2px;
        width: 14px;
      }

      &:checked:before {
        background: $dark-gray;
      }

      &:disabled {
        background: $white;
        cursor: not-allowed;
      }
    }

    .labelContainer {
      margin-left: 10px;
      display: flex;
      flex-direction: row;
    }

    .label {
      margin-left: 10px;
    }
  }
}
