.container {
  padding: 30px;

  .title {
    margin-bottom: 20px;
  }
  .formSection {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .input {
      width: 100%;
    }
    .textArea {
      min-height: 60px;
    }
    .dropDown {
      min-width: 150px;
    }
    .row {
      display: flex;
      flex-direction: row;
      gap: 5px;
    }
    .buttons {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 20px;
    }
  }
}
