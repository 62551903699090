@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.toggleContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  .toggleLabel {
    margin-right: 0.5em;
  }
}

.active {
  color: $gladiator;
}

.inactive {
  color: $text-gray;
}
