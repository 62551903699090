@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.typography/typography.module.scss';
@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

$border: 1px solid $light-gray;

.page {
  background-color: $lightest-gray;
  border: $border;
  display: flex;
  flex-direction: column;

  .title {
    color: $dark-gray;
    background-color: $lightest-gray;
    margin-top: 60px;
    display: flex;
    align-self: normal;

    .pageHeader {
      flex: 1;
      align-items: center;
      margin-left: 15px;
    }

    .createButton {
      vertical-align: middle;
      margin-right: 30px;

      @include tablet-medium {
        align-self: initial;
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-self: normal;
    gap: 50px;
    overflow: hidden;
    margin-top: 20px;

    @include tablet-small {
      padding: 0 30px;
    }

    .scrollContainer {
      overflow-x: auto;

      .table {
        border-collapse: separate;
        color: $dark-gray;
        width: 100%;
        border-spacing: 0;
        box-sizing: border-box;

        .tr {
          &:first-child {
            .th,
            .td {
              border-top: 0;
            }
          }

          &:last-child {
            .td {
              border-bottom: 0;
            }
          }

          .td,
          .th {
            .tableHeader {
              font-size: 16px;
              line-height: 22px;
              letter-spacing: -0.2px;
            }

            &:first-child {
              text-align: left;
              position: sticky;
              left: 0;
              top: auto;
              z-index: 2;
            }

            &:last-child {
              border-right: 0;
            }

            border-bottom: $border;
            border-right: $border;
            padding: 18px 15px;
            text-align: left;
            background-color: $white;
            word-break: break-word;
            min-width: 200px;
            max-width: 280px;

            @include tablet-small {
              max-width: none;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
