@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

.formContainer {
  padding: 30px;
}

.cardContainer {
  margin-top: 20px;
  padding: 20px 0px 30px 0px;
}

.paymentContainer {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.lblPaymentMethods {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-left: 30px;
  margin-bottom: 20px;
  color: $medium-gray;
  &[data-size='small'][data-weight='normal'] {
    letter-spacing: 1.5px;
  }
}

.cardList {
  row-gap: 20px;
  margin: 20px 10px 0px 10px;

  .emptyList {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 30px 0;
  }
}

.btnContainer {
  margin-bottom: 20px;
  margin-right: 20px;
}

.loader {
  margin-left: 20px;
}
