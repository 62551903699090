@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

.formContainer {
  padding: 20px 30px;
}
.billingCardForm {
  .formPart {
    padding-bottom: 20px;
  }
  .checkbox {
    padding-left: 0;
    margin-top: 20px;
    input[type='checkbox'] {
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .submitButton {
    padding-left: 15px;
    margin-top: 20px;

    @include tablet-small {
      padding-left: 0;
    }
  }
  .submitError {
    padding-left: 0px;
    margin-top: 20px;
    color: $gladiator;
  }
  .removeLink {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include tablet-small {
      padding-left: 0;
    }
  }
}

.cardToUpdate {
  margin-bottom: 20px;
}
