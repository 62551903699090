@import '~@campgladiator/cgui-core.styles.variables.typography/typography.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.input {
  font-weight: map-get($text-weights, book);
}

.input + [data-icon] {
  color: $medium-gray;
}
