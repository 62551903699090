@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

$border: 1px solid $light-gray;

.panelContainer {
  padding: 10px 10px 30px 10px;
  background-color: $white;
  justify-self: left;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 300px;
  box-sizing: border-box;
}

.infoContainer {
  margin-top: 20px;
}

.infoItem {
  color: $dark-gray;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  .infoIcon {
    margin-right: 5px;
  }
  .iconCheck {
    color: $gladiator-light;
  }
}

.trainerButton {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userName {
  text-align: center;
}
