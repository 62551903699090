@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.typography/typography.module.scss';

@mixin baseLayout {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.login {
  @include baseLayout;

  .form {
    @include baseLayout;
  }

  .proceed {
    @include baseLayout;
    align-items: center;

    @include tablet-small {
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    .button {
      width: 100%;

      @include tablet-small {
        margin-left: auto;
        width: initial;
      }
    }

    .forgotPassLink {
      align-self: center;

      @include tablet-small {
        align-self: flex-start;
      }
    }
  }
}
.pageHeader {
  .heading {
    color: $dark-gray;
  }

  .paragraph {
    color: $dark-gray;
    margin: 5px 0 20px;
  }
}
