@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';
@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';
@import '/src/components/module/trainer/rp-assignment-section/rp-assignment-section.module.scss';

.container {
  padding: 0 20px;

  @include tablet-medium {
    width: 50%;
  }

  .title {
    color: $dark-gray;
    margin-bottom: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .buttons {
      display: flex;
      flex-direction: row-reverse;
    }

    .submitButton {
      &[data-emphasis='primary'] {
        background-color: $navy-medium;
        &:disabled {
          background-color: $light-gray;
        }
        &:hover:enabled {
          background-color: $navy-medium;
        }
      }
    }
  }
}
