@import '~@campgladiator/cgui-core.styles.mixins/mixins.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

%shimmer-common {
  @include shimmer-effect(2000px, 4s);
  background-color: $medium-gray;
  flex-grow: 0;
  flex: none;
}

.tableContainer {
  max-width: calc(100vw - 360px);
  padding: 0 20px;
}

.headerView {
  margin-top: 0px;
}

.skeleton {
  @extend %shimmer-common;
  height: 18px;
  width: 160px;
  border-radius: 10px;
  margin-bottom: 10px;
}
