@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';

.container {
  margin: 0 auto 60px;
  max-width: 554px;
  width: 100%;

  @include tablet-medium {
    margin-top: 50px;
    margin-bottom: 110px;
  }

  @include tablet-large {
    margin-top: 30px;
  }
}
