@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.fileUploaderWrapper {
  display: flex;
  width: 100%;
}

.fileUploaderLabel {
  display: flex;
  width: 100%;
}

.input {
  display: none;
}

.fileUploader {
  display: flex;
  flex: 1;
  padding: 0;

  & [data-icon] {
    rotate: 45deg;
    color: $medium-gray;
  }
}
