@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.modalContainer {
  max-width: 600px !important;

  h2 {
    font-size: 28px !important;
  }
}

.modalSubTitle {
  color: $navy-medium;
  margin-bottom: 20px;
}
