@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

$border: 1px solid $light-gray;

.panelContainer {
  padding: 10px 10px 30px 10px;
  background-color: $white;
  justify-self: left;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.infoContainer {
  margin-top: 20px;
}

.infoItem {
  color: $dark-gray;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  .infoIcon {
    margin-right: 5px;
  }
}

.trainerButton {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $navy-light;
  height: 30px;
  width: 62px;
  border-radius: 10px;
  color: $white;

  .role {
    font-size: 12px;
  }
}

.imageContainer {
  .avatar {
    position: relative;
  }

  .editImage {
    position: absolute;
    z-index: 1;
    margin-left: 105px;
    margin-top: 105px;
    width: 36px;
    height: 36px;
    background-color: #fff;
    border: 1px solid #ededed;
    border-radius: 18px;
    justify-content: center;
    align-items: center;
    display: flex;

    .cameraIcon {
      color: $medium-gray;
    }

    input[type='file'] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.userName {
  text-align: center;
}
