.formContainer {
  max-width: 300px;
}

.cardholderNameInput {
  width: 300px;
}

.creditCardInput {
  width: 300px;
}

.expAndSecurityContainer {
  justify-content: space-between;

  .cardExpirationInput {
    max-width: 120px;
  }
  .cardSecurityCodeInput {
    max-width: 80px;
  }
}

.isDefaultToggleContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .isDefaultToggleInput {
    margin-right: 10px;
  }
}

.submitButtonContainer {
  margin-top: 30px;
}
