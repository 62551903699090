@import '~@campgladiator/cgui-core.styles.breakpoints/breakpoints.module.scss';
@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.container {
  padding: 20px 30px 20px 55px;
  background: $lightest-gray;

  .header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;

    @include tablet-medium {
      flex-direction: row;
      align-items: center;
      gap: 70px;
    }

    .title {
      color: $dark-gray;
    }
  }
}
