@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.dropdownContainer {
  position: relative;
}

.menu {
  position: absolute;
  z-index: 10;
  background-color: $white;
  margin-top: 10px;
  display: flex;

  div {
    max-height: 220px;
  }
}
