.currency {
  &[data-input-size='default'][data-size='small'] {
    padding-left: 40px;
    padding-right: 16px;
  }

  & + img {
    &[data-input-size='default'] {
      left: 10px;
    }
  }
}
