@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.title {
  color: $dark-gray;
}

.container {
  background: $lightest-gray;
  margin-top: 1px;
}

.teamContainer {
  margin: 30px;
}
