@import '~@campgladiator/cgui-core.styles.variables.colors/colors.module.scss';

.container {
  margin-top: 1px;
  background: $lightest-gray;
  display: flex;
  flex-direction: row;

  .spacer {
    padding: 0 20px;
  }

  .sidePanelContainer {
    padding: 10px;
    background-color: $white;
    max-width: 300px;
    box-sizing: border-box;
    justify-self: left;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .mainSection {
    width: 100%;
  }
}
